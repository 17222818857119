import React from 'react';
import { useSelector } from 'react-redux';
import selectProfile from 'redux-api/reselect/profile';

function TopBar() {
  const { languageCode } = useSelector((state) => state.config);
  const { customerType } = useSelector(selectProfile);

  return (
    languageCode === 'REMOVED' && (
      <div className="sl-top-bar">
        <div className="sl-top-bar-container">
          <div className="sl-top-bar-content">
            <span>
              Su Ready sconto del 20% su tutti i servizi editoriali, approfittane subito! Promo valida fino a venerdì 18 novembre 2022
            </span>
          </div>
          <div className="sl-top-bar-actions">
            <a href="https://readybooks.io" target="_blank" rel="noreferrer">
              <span>Vai su Ready</span>
            </a>
          </div>
        </div>
      </div>
    )
  );
}

export default TopBar;
