import React from 'react';

function Hide() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.05475 7.50925L2.159 4.6135C1.019 5.85625 0.587 7.10125 0.5795 7.12675L0.5 7.36375L0.57875 7.60075C0.59525 7.651 2.31575 12.6137 8.0405 12.6137C8.73725 12.6137 9.37175 12.5372 9.9545 12.409L7.895 10.3495C7.15346 10.3131 6.45196 10.0022 5.92699 9.47726C5.40201 8.95228 5.0911 8.25078 5.05475 7.50925ZM8.0405 2.11375C6.64925 2.11375 5.50925 2.41675 4.559 2.86225L1.78025 0.0834961L0.71975 1.144L14.2197 14.644L15.2803 13.5835L12.8068 11.11C14.7853 9.64525 15.491 7.63225 15.5015 7.60075L15.5802 7.36375L15.5015 7.12675C15.485 7.0765 13.7653 2.11375 8.0405 2.11375ZM9.47 7.77324C9.61025 7.26549 9.491 6.694 9.101 6.30325C8.711 5.9125 8.13875 5.794 7.631 5.93425L6.5 4.80325C6.96347 4.51823 7.49641 4.36619 8.0405 4.36375C9.695 4.36375 11.0405 5.70925 11.0405 7.36375C11.0383 7.90773 10.8859 8.44055 10.6003 8.9035L9.47 7.77324Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Hide;
