import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useDispatch, useSelector } from 'react-redux';
import { setWarningVisible, getWarningRequest } from 'redux-api/action/warning';
import selectProfile from 'redux-api/reselect/profile';
import selectWarning from 'redux-api/reselect/warning';
import Text from 'theme/text';
import Alert from 'theme/icons/alert';
import Hide from 'theme/icons/hide';
import translations from 'translations';
import _ from 'lodash';

const Wrapper = styled.div`
  ${space}

  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 100%;
  border-radius: 12px;

  & > .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: row;
    }

    & > div > span {
      color: ${themeGet('colors.warning')};
    }
  }

  & > .notification {
    border-radius: 4px;
    border: 1px solid ${themeGet('colors.accent')};
    background: ${themeGet('colors.white')};
    border-left: 4px solid ${themeGet('colors.warning')};
    padding: ${themeGet('space.xs')}px;
    margin: ${themeGet('space.xs')}px 0;
  }
`;

function WarningNotification({ style, className }) {
  const dispatch = useDispatch();

  const { languageCode } = useSelector((state) => state.config);
  const { isVisible } = useSelector((state) => state.warning);
  const warnings = useSelector(selectWarning);

  useEffect(() => {
    dispatch(getWarningRequest());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(setWarningVisible(false));
  }, [dispatch]);

  return (
    <Wrapper isVisible={isVisible && warnings.length} {...style} className={className}>
      <div className="head">
        <div>
          <span>
            <Alert />
          </span>
          <Text fontSize="h4" lineHeight="h4" fontWeight="bold" color="grey_600" ml="10px">
            {translations[languageCode].warning}
          </Text>
        </div>
        <button title={translations[languageCode].warning} type="button" onClick={handleClose}>
          <Hide />
        </button>
      </div>

      {warnings.map(({ key, message }) => (
        <div key={key} className="notification">
          {message}
        </div>
      ))}
    </Wrapper>
  );
}

export default WarningNotification;
