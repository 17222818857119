/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import formatCurrency, { formatCurrencyWithLanguage } from 'lib/format-currency';
import { createSelector } from 'reselect';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import translations from 'translations';
import configSelector from './config';
import { capitalizeFirstLetter } from '../../lib/utils';

const formatDate = 'MMMM YYYY';

const selectSummary = createSelector(
  (state) => state.billing.summary,
  configSelector,
  (payload, { languageCode }) => {
    if (!payload) {
      return {
        nextInvoicing: null,
        availableBalance: null,
        totalEarned: null,
        lastEarned: null,
        earningsPercentage: null,
        temporaryRevenuesNotBilled: null,
        estimatedPaymentOn: null,
      };
    }

    const {
      attributes: {
        balanceAvailable,
        totalEarned,
        lastEarned,
        nextInvoiceOn,
        temporaryRevenuesNotBilled,
        invoicingPeriodCode,
        estimatedPaymentOn,
        paymentTerm,
      },
    } = payload;

    moment.locale(languageCode);

    return {
      nextInvoicing: nextInvoiceOn
        ? capitalizeFirstLetter(moment(nextInvoiceOn, 'YYYY-MM-GG').format(formatDate))
        : translations[languageCode]['no available'],
      invoicingPeriodCode: translations[languageCode][invoicingPeriodCode],
      availableBalance: balanceAvailable.amount
        ? formatCurrency(balanceAvailable.currency, balanceAvailable.amount)
        : translations[languageCode]['no available'],
      totalEarned: totalEarned.amount
        ? formatCurrency(totalEarned.currency, totalEarned.amount)
        : translations[languageCode]['no available'],
      lastEarned: lastEarned.amount
        ? formatCurrency(lastEarned.currency, lastEarned.amount)
        : translations[languageCode]['no available'],
      earningsPercentage: lastEarned.percentageDiff ?? 0,
      temporaryRevenuesNotBilled: temporaryRevenuesNotBilled.amount
        ? formatCurrency(temporaryRevenuesNotBilled.currency, temporaryRevenuesNotBilled.amount)
        : translations[languageCode]['no available'],
      estimatedPaymentOn: estimatedPaymentOn
        ? capitalizeFirstLetter(moment(estimatedPaymentOn, 'YYYY-MM-GG').format(formatDate))
        : translations[languageCode]['no available'],
      paymentTerm:
        paymentTerm && paymentTerm.code
          ? translations[languageCode][paymentTerm.code]
          : translations[languageCode]['no available'],
    };
  },
);

const selectDocuments = createSelector(
  (state) => state.billing.documents,
  configSelector,
  (payload, { languageCode }) => {
    if (!payload)
      return {
        data: [],
        totalCount: 0,
      };

    moment.locale(languageCode);

    const { data, meta } = payload;

    const formattedData = data.map((invoice) => {
      const { id, attributes } = invoice;

      let periods = [];
      if (attributes.sumAmountsByPeriods) {
        periods = attributes.sumAmountsByPeriods.map(({ periodCode }) =>
          moment(periodCode, 'YYYY-MM').format('MM-YYYY'),
        );
      }

      return {
        ...attributes,
        key: id,
        id,
        issuedOn: attributes.issuedOn ? moment(attributes.issuedOn).format('DD MMM YYYY') : '',
        approvedOn: attributes.approvedOn
          ? moment(attributes.approvedOn).format('DD MMM YYYY')
          : attributes.issuedOn
          ? moment(attributes.issuedOn).format('DD MMM YYYY')
          : '-',
        paidOn: attributes.paidOn ? moment(attributes.paidOn).format('DD MMM YYYY') : null,
        amount: formatCurrency(attributes.currencyCode, attributes.amount ?? 0),
        periods,
      };
    });

    return {
      data: formattedData,
      totalCount: meta.totalCount,
    };
  },
);

const selectPaymentAccounts = createSelector(
  (state) => state.billing.paymentAccounts,
  (payload) => {
    if (!payload) return { total: 0, totalDraft: 0, paymentAccounts: [] };

    const { data } = payload;

    return {
      total: data.length,
      totalDraft: 0,
      paymentAccounts: data
        .map(({ attributes, relationships: { billingProfiles } }) => {
          const {
            pid,
            label,
            paymentMethodCode,
            bankName,
            ownerName,
            iban,
            bic,
            accountNumber,
            routingNumber,
            paypal,
            payoneer,
            payoneerInternal,
            sureRemitWallet,
            sureRemitMemo,
            countryCode,
            createdAt,
            updatedAt,
          } = attributes;

          return {
            id: pid,
            label,
            paymentMethodCode,
            isDraft: false,
            billingProfilesIds: billingProfiles.data
              ? billingProfiles.data.map(({ id }) => id)
              : [],
            billingProfilesCounter:
              billingProfiles && billingProfiles.data ? billingProfiles.data.length : 0,
            bankName,
            ownerName,
            iban,
            bic,
            accountNumber,
            routingNumber,
            paypal,
            payoneer,
            payoneerInternal,
            sureRemitWallet,
            sureRemitMemo,
            countryCode,
            createdAt: createdAt ? moment(createdAt, 'YYYY-MM-DD').format('L') : '',
            updatedAt: updatedAt ? moment(updatedAt, 'YYYY-MM-DD').format('L') : '',
          };
        })
        .sort(
          (a, b) =>
            `${a.paymentMethodCode}`.localeCompare(b.paymentMethodCode) ||
            `${a.label}`.localeCompare(b.label),
        ),
    };
  },
);

const selectBillingProfiles = createSelector(
  (state) => state.billing.profiles,
  selectPaymentAccounts,
  (payload, { paymentAccounts }) => {
    if (!payload) return { total: 0, totalDraft: 0, profiles: [] };

    const { data } = payload;

    let totalDraft = 0;
    data.forEach((profile) => {
      const {
        attributes: { validForPassiveInvoice },
      } = profile;

      if (!validForPassiveInvoice) totalDraft += 1;
    });

    return {
      total: data.length,
      totalDraft,
      profiles: data
        .map(({ attributes, relationships: { paymentAccount: paymentAccountRel } }) => {
          const {
            pid,
            label,
            appellation,
            default: isDefault,
            billingModeCode,
            countryEu,
            firstName,
            lastName,
            bornOn,
            birthPostalCode,
            birthCity,
            birthCountrySub,
            birthCountry,
            sex,
            address,
            addressAlt,
            city,
            personalTin,
            companyTin,
            countrySub,
            countryCode,
            recipientCode,
            postalCode,
            email,
            phone,
            vatModeCode,
            validForPassiveInvoice,
            validationPassiveInvoiceErrors,
            validForActiveInvoice,
            validationActiveInvoiceErrors,
            withheldCode,
            pensionModeCode,
            pensionInstitute,
            createdAt,
            updatedAt,
          } = attributes;

          let paymentAccount = {};
          let paymentId = null;

          if (paymentAccountRel && paymentAccountRel.data && paymentAccountRel.data.id) {
            paymentId = paymentAccountRel.data.id;

            const paymentAccountFinded = paymentAccounts.find(
              ({ id }) => paymentAccountRel.data.id === id,
            );

            if (paymentAccountFinded) {
              paymentAccount = paymentAccountFinded;
            }
          }

          return {
            id: pid,
            label,
            appellation,
            isDefault,
            billingModeCode,
            countryEu,
            recipientCode,
            bornOn: bornOn ? moment(bornOn, 'YYYY-MM-DD') : null,
            birthPostalCode,
            birthCity,
            birthCountrySub,
            birthCountry,
            sex,
            firstName,
            lastName,
            address,
            addressAlt,
            city,
            personalTin,
            companyTin,
            countrySub,
            countryCode: !countryCode ? null : countryCode,
            postalCode,
            email,
            phone,
            vatModeCode,
            pensionModeCode,
            pensionInstitute,
            validForPassiveInvoice,
            validationPassiveInvoiceErrors,
            validForActiveInvoice,
            validationActiveInvoiceErrors,
            paymentId,
            paymentAccount,
            withheldCode,
            isDraft: !validForPassiveInvoice,
            createdAt: createdAt ? moment(createdAt, 'YYYY-MM-DD').format('L') : '',
            updatedAt: updatedAt ? moment(updatedAt, 'YYYY-MM-DD').format('L') : '',
          };
        })
        .sort((a, b) => (a.isDefault ? -1 : b.isDefault ? 1 : `${a.label}`.localeCompare(b.label))),
    };
  },
);

const selectDefaultBillingProfile = createSelector(selectBillingProfiles, ({ profiles }) => {
  if (profiles.length === 0) return { paymentAccount: {} };

  return profiles.find((billingProfile) => billingProfile.isDefault);
});

const selectBillingFormErrors = createSelector(
  (state) => state.billing.errors,
  (payload) => {
    let errors = {};

    if (payload && payload.status === 'CUSTOM-ERROR') {
      payload.data.errors.forEach(({ type, detail }) => {
        const field = type.split(':')[1];

        errors = {
          ...errors,
          [field]: detail,
        };
      });
    }

    return errors;
  },
);

const selectPaymentFormErrors = createSelector(
  (state) => state.billing.errors,
  (payload) => {
    let errors = {};

    if (payload && payload.status === 'CUSTOM-ERROR') {
      payload.data.errors.forEach(({ type, detail }) => {
        const field = type.split(':')[1];

        errors = {
          ...errors,
          [field]: detail,
        };
      });
    }

    return errors;
  },
);

const selectDocumentPassive = createSelector(
  (state) => state.billing.document,
  configSelector,
  (payload, { languageCode }) => {
    if (!payload) {
      return {
        rows: [],
        timeline: [],
        companyInfo: {},
      };
    }

    const {
      billingProfilePid,
      data: {
        id: pid,
        attributes: {
          typologyCode,
          issuedNumber,
          issuedOn,
          issuableNumber,
          issuableOn,
          status,
          currencyCode,
          rows,
          footer,
          amount,
          companyInfo,
          timeline,
          minIssuableOn,
          approvedOn,
          approvedOnSelector,
          permissions,
          synthetic,
          ripartition,
        },
        links: { downloadPdf },
      },
    } = payload.invoice;

    if (typologyCode !== 'PASSIVE') {
      return {
        rows: [],
        timeline: [],
        companyInfo: {},
      };
    }

    moment.locale(languageCode);

    const {
      data: {
        attributes: {
          label,
          appellation,
          address,
          city,
          postalCode,
          countrySub,
          countryCode,
          personalTin,
          companyTin,
          billingModeCode,
        },
      },
    } = payload.billingProfile;

    const {
      data: {
        attributes: { paymentMethodCode },
      },
    } = payload.paymentAccount;

    let objectRows = {};

    rows.forEach((row) => {
      const key = moment(row.period.name, 'YYYY-MM').format('MMM YYYY');

      objectRows = {
        ...objectRows,
        [key]: {
          ...objectRows[key],
          title: key,
          partialRight: objectRows[key]
            ? Number(objectRows[key].partialRight) + Number(row.amount)
            : Number(row.amount),
          data: [],
        },
      };
    });

    rows.forEach((row) => {
      const key = moment(row.period.name, 'YYYY-MM').format('MMM YYYY');
      const id = uuidv4();

      objectRows = {
        ...objectRows,
        [key]: {
          ...objectRows[key],
          title: key,
          right: formatCurrency(currencyCode, objectRows[key].partialRight),
          data: [
            ...objectRows[key].data,
            {
              id,
              key: id,
              month_of_sale: moment(row.period.name, 'YYYY-MM').format('MMM YYYY').toUpperCase(),
              publisher: row.publisher && row.publisher.name ? row.publisher.name : null,
              store_name: row.store && row.store.name ? row.store.name : null,
              item_type: row.itemType && row.itemType.code ? row.itemType.code.toLowerCase() : null,
              currency: row.currency && row.currency.code ? row.currency.code : null,
              currencyAmount: formatCurrencyWithLanguage(row.currency.code, row.originalAmount),
              amount: formatCurrencyWithLanguage(currencyCode, row.amount),
              fxRate: row.fxRate,
              floatAmount: row.amount,
              ruleExtid: row.ruleExtid,
              recipientSlid: row.recipientSlid,
              note: row.note,
            },
          ],
        },
      };
    });

    return {
      pid,
      id: pid,
      billingProfileLabel: label,
      appellation,
      address,
      city,
      postalCode,
      countrySub,
      countryCode,
      personalTin,
      companyTin,
      billingModeCode,
      issuableNumber,
      issuableOn,
      issuedNumber,
      permissions,
      synthetic,
      ripartition,
      approvedOnSelector,
      approvedOn: approvedOn
        ? moment(approvedOn, 'YYYY-MM-DD').format('L')
        : issuedOn
        ? moment(issuedOn, 'YYYY-MM-DD').format('L')
        : '',
      issuedOn: issuedOn ? moment(issuedOn, 'YYYY-MM-DD').format('L') : '',
      subTotal: formatCurrency(currencyCode, amount ?? 0),
      total: formatCurrency(currencyCode, footer.toPayAmount ?? 0),
      paymentMethod: footer.paymentSummary ? footer.paymentSummary.paymentMethodCode : null,
      paymentMethodRecipient:
        footer.paymentSummary && footer.paymentSummary.recipient
          ? footer.paymentSummary.recipient.value
          : null,
      paymentMethodCode,
      pension: footer.pension.amount
        ? formatCurrency(currencyCode, footer.pension.amount ?? 0)
        : null,
      pensionFormula: footer.pension.formula || null,
      fees: footer.paymentFee.amount
        ? formatCurrency(currencyCode, footer.paymentFee.amount ?? 0)
        : null,
      stamp: footer.stamp.amount ? formatCurrency(currencyCode, footer.stamp.amount ?? 0) : null,
      withheld: footer.withheld.amount
        ? formatCurrency(currencyCode, footer.withheld.amount ?? 0)
        : null,
      vat: footer.vat.amount ? formatCurrency(currencyCode, footer.vat.amount ?? 0) : formatCurrency(currencyCode, 0),
      vatAmount: footer.vat.amount || 0,
      vatFormula: footer.vat.formula ? `(${footer.vat.formula})` : '',
      vatMessage: footer.vat.message ? `(${footer.vat.message})` : '',
      vatCode: footer.vat.code ? `(${footer.vat.code})` : '',
      formula: footer.withheld.formula ? `(${footer.withheld.formula})` : '',
      rows: Object.keys(objectRows).map((key) => objectRows[key]),
      companyInfo,
      status: status === 'DRAFT' ? 'DRAFT' : 'PASSIVE',
      timeline: timeline ?? [],
      minIssuableOn: minIssuableOn ? moment(minIssuableOn, 'YYYY-MM-DD') : null,
      invoiceLink: downloadPdf,
      billingProfilePid,
    };
  },
);

const selectDocumentActive = createSelector(
  (state) => state.billing.document,
  (payload) => {
    if (!payload) {
      return {
        rows: [],
        timeline: [],
        companyInfo: {},
      };
    }

    const {
      id: pid,
      data: {
        attributes: {
          paymentMethod,
          typologyCode,
          issuedNumber,
          imsDocumentRegNumber,
          issuedOn,
          currencyCode,
          rows,
          totalAmount,
          netAmount,
          timeline,
          companyInfo,
          vatAmount,
        },
        links: { downloadPdf },
      },
    } = payload.invoice;

    if (typologyCode !== 'ACTIVE') {
      return {
        rows: [],
        timeline: [],
        companyInfo: {},
      };
    }

    const {
      data: {
        attributes: {
          label,
          appellation,
          address,
          city,
          postalCode,
          countrySub,
          countryCode,
          personalTin,
          companyTin,
          billingModeCode,
        },
      },
    } = payload.billingProfile;

    const parsedRows = rows.map((row) => {
      const id = uuidv4();

      return {
        ...row,
        key: id,
        currency: currencyCode,
        unitPrice: formatCurrency(currencyCode, row.unitPrice),
        isDiscount: row.unitPrice < 0,
      };
    });

    return {
      pid,
      id: pid,
      billingModeCode,
      paymentMethodCode: paymentMethod,
      billingProfileLabel: label,
      appellation,
      address,
      city,
      postalCode,
      countrySub,
      countryCode,
      personalTin,
      companyTin,
      issuedNumber,
      imsDocumentRegNumber,
      issuedOn: issuedOn ? moment(issuedOn, 'YYYY-MM-DD').format('L') : '',
      vatAmount: formatCurrency(currencyCode, vatAmount ?? 0),
      subTotal: formatCurrency(currencyCode, netAmount ?? 0),
      total: formatCurrency(currencyCode, totalAmount ?? 0),
      rows: parsedRows,
      status: 'ACTIVE',
      timeline: timeline ?? [],
      companyInfo,
      invoiceLink: downloadPdf,
    };
  },
);

const selectLastWithdrawal = createSelector(
  (state) => state.billing.lastWithdrawal,
  configSelector,
  (payload, { languageCode }) => {
    if (!payload || !payload?.data) return { status: 'processed' };

    moment.locale(languageCode);

    const {
      data: {
        attributes: {
          id,
          customerCode,
          failureDescription,
          nextAt,
          processedAt,
          requestedAt,
          status,
          translatedStatus,
        },
      },
    } = payload;

    return {
      id,
      customerCode,
      failureDescription,
      nextAt: moment(nextAt).format('L'),
      processedAt: moment(processedAt).format('L'),
      requestedAt: moment(requestedAt).format('L'),
      status,
      translatedStatus,
    };
  },
);

export default selectSummary;
export {
  selectDocuments,
  selectDocumentPassive,
  selectDocumentActive,
  selectBillingProfiles,
  selectPaymentAccounts,
  selectDefaultBillingProfile,
  selectBillingFormErrors,
  selectPaymentFormErrors,
  selectLastWithdrawal,
};
