const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatDateString = {
  it: 'DD MMM',
  pt: 'DD MMM',
  de: 'DD MMM',
  en: 'MMM DD',
};

export { capitalizeFirstLetter, formatDateString };
